<!--配送管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-input placeholder="请输入配送人员姓名/手机号" v-model="select.search" size="small" style="width:300px;" @change="curr=1;getList()">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
                <el-button type="primary" block size="small" @click="edit()">添加配送人员</el-button>
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="name" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="mobile" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column label="照片" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image class="tableImage" :src="domain + scope.row.image" :preview-src-list="[domain + scope.row.image]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="warehouse.name" label="所属门店" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!-- 新建框 -->
        <div v-if="dialogFormVisible">
            <el-dialog :title="title" custom-class="way" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="650px">
                <el-form ref="formAuth" :rules="formRules" :model="formData" label-position="top" size="small" class="alertForm">
                    <el-form-item label="所属门店" prop="warehouse_id" style="width:100%">
                        <el-select v-model="formData.warehouse_id" placeholder="选择门店" style="width:100%" clearable>
                            <el-option v-for="item in houseList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name" style="width:100%">
                        <el-input v-model="formData.name" placeholder="请输入姓名" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile" style="width:100%">
                        <el-input v-model="formData.mobile" placeholder="请输入手机号" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="设置密码" :prop="ispwd?'password':''" style="width:100%">
                        <el-input v-model="formData.password" placeholder="请输入密码(如无需修改留空即可)" show-password autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="照片" prop="image" style="width:100%">
                        <uploadImage :imageList = "formData.image" :limitNum="limit" :clickFile="'image'" @upload="uploadFile"></uploadImage>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="closeDialog()">取 消</el-button>
                    <el-button size="small" type="primary" @click="submit()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    import {distributionListApi,houseListApi,distributionEditApi,distributionDeleteApi}  from '@/api/distribution.js';
    import uploadImage from '@/components/uploadImage';
    export default {
        data() {
            return {
                formRules:{
                    warehouse_id:[{
                        required: true,
                        message: '请选择门店',
                        trigger: 'change'
                    }],
                    name: [{
                        required: true,
                        message: '请输入姓名',
                        trigger: 'change'
                    }],
                    mobile: [{
                        required: true,
                        pattern: /^1[0-9][0-9]\d{8}$/,
                        message: '请输入手机号',
                        trigger: 'change'
                    }],
                    password: [{
                        required: true,
                        message: '请输入密码',
                        trigger: 'change'
                    }],
                    image: [{
                        required: true,
                        message: '请上传照片',
                        trigger: 'change'
                    }],
                },
                formData:{}, //详情数据
                list:[],  //配送员列表
                houseList:[], //门店列表
                select:{},

                title:'添加配送人员',

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
                limit:1,

                dialogFormVisible:false, //弹出框状态
                ispwd:false
            }
        },
        components:{
            uploadImage
        },
        mounted() {
            this.getList();
            this.getHouseList();
        },
        methods: {
            //配送列表
            getList: function(){
                distributionListApi({
                    curr:this.curr,
                    row:this.row,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },
            //门店列表
            getHouseList: function(){
                houseListApi().then(response=>{
                    this.houseList = response;
                })
            },
            //接收图片地址
            uploadFile:function(file){
                this.formData.image = file.image;
            },
            //编辑配送人员信息
            edit: function(param = {}){
                this.dialogFormVisible = true;
                if(typeof param.id == 'undefined'){
                    this.title = '添加配送人员';
                    this.formData = {
                        image:[]
                    };
                    this.ispwd = true;
                }else{
                    this.ispwd = false;
                    this.title = '编辑配送人员';
                    this.formData = JSON.parse(JSON.stringify(param));
                    this.formData.image = [{
                        url:this.formData.image
                    }]
                }
            },

            //删除配送人员
            deletes: function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    distributionDeleteApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{})
            },
            //提交信息
            submit: function(){
                this.$refs['formAuth'].validate((valid)=>{
                    if(valid){
                        var pwd = this.formData.password ? this.$md5(this.formData.password) : this.formData.password
                        distributionEditApi({
                            id:this.formData.id,
                            mobile:this.formData.mobile,
                            name:this.formData.name,
                            image:this.formData.image[0].url,
                            password:pwd,
                            warehouse_id:this.formData.warehouse_id
                        }).then(()=>{
                            this.dialogFormVisible = false;
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '保存成功!'
                            });
                        })
                    }
                })
            },
            closeDialog:function(){
                this.$refs['formAuth'].resetFields();
                this.dialogFormVisible = false;
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>